// @ts-nocheck
import React, { useEffect, useState } from 'react'
import style from '~/styles/home.module.scss'
import { PimHomeService } from '~/services/pim.service'
import { Logger } from '~/util'
import { AppHead } from '~/components/generals/app-head/app-head'
// import { Config } from '~/config/config'
import { Config } from '../config/config'
import Cookies from 'cookies'
import { Channel } from '~/types/pim'
import Mobilehomeui from '~/components/ui/MobileHomeUI/Mobilehomeui'
import Overlay from '~/components/ui/overlay/overlay'
import Spinner from '~/components/ui/spinner/spinner'
import { remove } from 'js-cookie'
import Script from 'next/script'

export const runtime = 'experimental-edge'

const Page = (props: any) => {
  console.log('home page props1')
  // const test = JSON.parse(props)
  // console.log('home page props', test, JSON.parse(props))
  return (
    <div className={`${style.container}`}>
      <AppHead
        title={props.data.pageContent?.seo.title}
        description={''}
        metaData={props.data.pageContent?.seo.metaTags}
        canonical={`${Config.SITE_URL}`}
      >
        {/* <script
          type="application/ld+json"
          defer
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "url": "${Config.SITE_URL}",
              "potentialAction": {
                   "@type": "SearchAction",
                   "target": "https://www.poorvika.com/{search_term_string}",
                   "query-input": "required name=search_term_string"
                 },

              ${
                props?.data?.pageContent
                  ? `"Organization": {
                "@type": "Organization",
                "name": "Poorvika Mobiles Private Limited ",
                "url": "${Config.SITE_URL}",
                "logo": "https://img.poorvika.com/common/Poorvika-english-logo.svg",
                "image": [
                  "https://img.poorvika.com/common/Poorvika-english-logo.svg"
                 ],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "No.30, Arcot Road",
                  "addressLocality": "Kodambakkam,Chennai",
                  "addressRegion": "India",
                  "postalCode": "6000024",
                  "Telephone": "044-43666666"
               },
              "sameAs": [
              "https://www.instagram.com/poorvika_india/",
              "https://www.facebook.com/poorvikamobile/",
              "https://twitter.com/poorvika_india",
              "https://www.youtube.com/channel/UC6N3zABqbVuPR1xDdp5sDng",
              "https://www.linkedin.com/company/poorvika-mobiles-private-limited",
              "https://www.threads.net/@poorvika_india"
              ]
              }},`
                  : ''
              }
            }
            `,
          }}
        ></script> */}
      </AppHead>

      {!!props.data.pageContent ? (
        <Mobilehomeui features={props.data?.pageContent}></Mobilehomeui>
      ) : (
        <div>
          <Overlay type="CONTAINER">
            <Spinner />
          </Overlay>
        </div>
      )}
    </div>
  )
}

export async function getServerSideProps(ctx) {
  try {
    // const cookies = new Cookies()
    // console.log("cookiescookies",cookies);

    // const store = cookies.get('store')

    const resp = await PimHomeService.PimPageUsingAxios(
      Config.HOME_PAGE_SLUG,
      // !!store ? Channel.SHOP : Channel.ONLINE,
      'ONLINE',
      'en-IN'
    )

    const res = await resp.json()
    console.log('home page', res)

    ctx.res.setHeader(
      'Cache-Control',
      'public, s-maxage=604800, stale-while-revalidate=86400'
    )

    let userAgent = /Mobile/.test(ctx.req.headers['user-agent'])

    let mode = 'web'
    if (userAgent) {
      mode = 'mobileweb'
    }
    console.log('home page last', res.data)
    return {
      props: {
        mode: mode,
        data: res.data,
      },
    }
  } catch (err) {
    Logger.error('{ERROR: [', err)
    return {
      props: {
        data: `${err}`,
      },
    }
  }
}

Page.pageName = 'home'
export default Page
